import React, { useState } from "react";
import { Point } from "../models/cube-coordinates";

import { SkillPanel } from "../models/skill-panel";

type Props = {
  position: Point;
  width: number;
  height: number;
  panel: SkillPanel;
  onClick?: (position: Point) => void;
  explore: boolean;
};

const states = ["notavailable", "available", "obtained"];

function Skill(props: Props) {
  let icon = require(`../assets/placeholder.png`);
  if (props.panel) {
    icon = require(`../assets/${props.panel.icon}.png`);
  }

  const position = props.position;
  const width = props.width;
  const height = props.height;
  const yOffset =
    position.x % 2 === 1
      ? height / 2 - (height / 14) * position.y
      : (-height / 14) * position.y;
  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        visibility: "hidden",
        transform: ` translateX(${
          (-width / 3.5) * position.x
        }px) translateY(${yOffset}px) rotate(120deg)`,
        height: `${height}px`,
        width: `${width}px`,
      }}
      onClick={() => {
        if (props.onClick) {
          console.log(`Panel at ${position.x}, ${position.y}`);
          props.onClick(position);
        }
      }}
    >
      <div
        style={{
          overflow: "hidden",
          width: "100%",
          height: "100%",
          transform: "rotate(-60deg)",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            opacity: props.panel.icon === "placeholder" ? 0 : 1,
            position: "absolute",
            backgroundColor: icon ? "" : "teal",
            backgroundRepeat: "no-repeat",
            backgroundPosition: `${
              props.explore ? 98 : 98
              // : props.panel.icon !== "start"
              // ? states.indexOf(props.panel.status) * 49
              // : 0
            }%`,
            backgroundSize: "cover",
            backgroundImage: icon ? `url(${icon})` : "",
            visibility: "visible",
            transform: "rotate(-60deg)",
          }}
        ></div>
      </div>
    </div>
  );
}

export default Skill;
