import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Menu as MenuIcon } from "@material-ui/icons";
import { Avatar, Theme, createStyles } from "@material-ui/core";
import { CharacterName } from "../models/character";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  })
);

type Anchor = "left";

type Props = {
  onCharacterSelect: (character: CharacterName) => void;
};

const characters: { name: CharacterName; icon: string }[] = [
  {
    name: "luminary",
    icon: require(`../assets/characters/luminary-on.png`),
  },
  {
    name: "erik",
    icon: require(`../assets/characters/erik-on.png`),
  },
  {
    name: "serena",
    icon: require(`../assets/characters/serena-on.png`),
  },
  {
    name: "veronica",
    icon: require(`../assets/characters/veronica-on.png`),
  },
  {
    name: "sylvando",
    icon: require(`../assets/characters/sylvando-on.png`),
  },
  {
    name: "rab",
    icon: require(`../assets/characters/rab-on.png`),
  },
  {
    name: "jade",
    icon: require(`../assets/characters/jade-on.png`),
  },
  {
    name: "eight",
    icon: require(`../assets/characters/eight-on.png`),
  },
];

export function MainMenu(props: Props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
    selectedCharacter: "",
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {characters.map((character, index) => (
          <ListItem
            button
            key={character.name}
            onClick={() => props.onCharacterSelect(character.name)}
          >
            <Avatar
              variant="square"
              src={character.icon}
              className={classes.icon}
              style={character.name === "eight" ? {filter: " brightness(0.10)"} : {}}
            ></Avatar>
            <ListItemText
              primary={character.name}
              style={{ textTransform: "capitalize" }}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  return (
    <div>
      {(["left"] as Anchor[]).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <MenuIcon style={{ color: "White" }} />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
