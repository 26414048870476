import React, { useState, useEffect, useRef } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, AppBar, Toolbar } from "@material-ui/core";
import SkillTree from "./SkillTree";
import { SkillTreeModel, SkillTreeDataModel } from "../models/skill-tree";
import { Point } from "../models/cube-coordinates";
import { SkillInfo } from "./SkillInfo";
import { SkillPanel } from "../models/skill-panel";
import { ActMenu } from "./ActMenu";
import { CharacterHeader } from "./CharacterHeader";
import { MainMenu } from "./MainMenu";
import { CharacterName, isCharacterName } from "../models/character";
import { SkillInfoFinal } from "./SkillInfoFinal";

export type EditorModes = "stamp" | "explore" | "erase" | "plan";

const skillTrees = require("../data/data.json");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      justifyContent: "space-between",
    },
    avatar: {
      height: theme.spacing(5),
    },
  })
);

export function Explore() {
  const classes = useStyles();
  const [mode, setMode] = useState("explore");
  const [acts, setActs] = useState<number[]>([]);
  const [selectedAct, setSelectedAct] = useState<number | undefined>();
  const [selectedPanel, setSelectedPanel] = useState<SkillPanel | undefined>();
  const [currentSkill, setCurrentSkill] = useState<SkillPanel>();
  const [character, setCharacter] = useState<CharacterName | undefined>();
  const ref = useRef<HTMLDivElement>(null);
  const viewportSize = useWindowSize();
  const [skillTree, setSkillTree] = useState<SkillTreeModel | undefined>();
  const [panels, setPanels] = useState<SkillPanel[]>([]);

  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        if (ref === null || ref.current === null) {
          return;
        }
        // setHeight(ref.current.clientHeight);
        // Set window width/height to state
        setWindowSize({
          width: ref.current.clientWidth,
          height: ref.current.clientHeight,
        });
      }

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
  }
  useEffect(() => {
    const defaultCharacter = "luminary";

    let character = localStorage.getItem("selectedCharacter");
    if (character !== null && isCharacterName(character)) {
      setCharacter(character);
      return;
    }
    setCharacter(defaultCharacter);
    localStorage.setItem("selectedCharacter", defaultCharacter);
  }, []);

  useEffect(() => {
    if (!selectedPanel) {
      return;
    }
    console.log(
      `finding panel at position x:${selectedPanel.position.x} y: ${selectedPanel.position.y}`
    );
    const panel = panels.find((panel) => {
      return (
        panel.position.x === selectedPanel.position.x &&
        panel.position.y === selectedPanel.position.y
      );
    });
    if (panel) {
      console.log("Panel found");
      if (panel.skill) {
        console.log(`Loading skill ${panel.skill}`);
      }
      setCurrentSkill(panel);
    }
  }, [selectedPanel, panels]);

  const saveSkill = (
    skill: string,
    cost: number,
    type: "normal" | "hidden",
    surprise: boolean
  ) => {
    if (!selectedPanel) {
      return;
    }
    console.log(`Inside save skill`);
    const panel = panels.find((panel) => {
      return (
        panel.position.x === selectedPanel.position.x &&
        panel.position.y === selectedPanel.position.y
      );
    });
    if (panel) {
      console.log(`saving skill ${skill}`);
      panel.skill = skill;
      panel.cost = cost;
    }
  };

  const onCharacterSelect = (character: CharacterName) => {
    setCharacter(character);
    localStorage.setItem("selectedCharacter", character);
    console.log(`Character "${character}" selected`);
  };

  /**
   * Handle switching characters
   */
  useEffect(() => {
    if (!character) {
      return;
    }
    const characterActs = getCharacterActs(character);
    let act = characterActs[0];
    setActs(characterActs);
    let storedAct = localStorage.getItem(`${character}-explore-act`);
    if (storedAct !== null) {
      const auxAct = parseInt(storedAct);
      if (characterActs.includes(auxAct)) {
        act = auxAct;
      }
    }
    setSelectedAct(act);
    setSelectedPanel(undefined);
    updateSkillTree(act);
  }, [character]);

  /**
   * Handle switching acts
   */
  useEffect(() => {
    if (!selectedAct) {
      return;
    }
    setSelectedPanel(undefined);
    updateSkillTree(selectedAct);
  }, [selectedAct]);

  function updateSkillTree(act: number) {
    const skilltree = skillTrees.find((skilltree: SkillTreeDataModel) => {
      return skilltree.name === `${character}-act${act}`;
    });
    if (skilltree) {
      const skillT = new SkillTreeModel(skilltree);
      setSkillTree(skillT);
      if (!skillT) {
        console.log(`SkillTree undefined`);
        return;
      }
      console.log(`Width: ${skillT.width} Height: ${skillT.height}`);
      setPanels(skillT.panels);
    } else {
      console.log(`${character}-act${act} NOT FOUND`);
    }
  }

  const background = require(`../assets/back_panel.png`);

  const onPanelClick = (position: Point) => {
    const panel = panels.find((panel) => {
      return panel.position.x === position.x && panel.position.y === position.y;
    });
    setSelectedPanel(panel);

    switch (mode) {
      case "plan":
        {
          // skillTree.getLearntNeighboors(position);
        }
        break;
      case "explore":
        {
          console.log("Updating selected panel");
          setSelectedPanel(panel);
        }
        break;
    }
  };

  // Move to a helper class?
  function getCharacterActs(character: CharacterName) {
    const characterActs = (skillTrees as { name: string }[])
      .filter((skilltree) => skilltree.name.includes(character))
      .map((skilltree) => {
        return parseInt(skilltree.name.substr(-1, 1));
      });
    console.log(acts);
    return characterActs;
  }

  function saveAct(act: number) {
    localStorage.setItem(`${character}-explore-act`, act.toString());
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      <div>
        <AppBar position="static">
          <Toolbar
            className={classes.root}
            style={{ backgroundColor: "#2c1404" }}
          >
            <MainMenu onCharacterSelect={onCharacterSelect}></MainMenu>
            {character && (
              <CharacterHeader character={character}></CharacterHeader>
            )}
            <ActMenu
              acts={acts}
              selectedAct={selectedAct}
              onActSelection={(act: number) => {
                setSelectedAct(act);
                saveAct(act);
                console.log(`Act ${act} selected`);
              }}
            ></ActMenu>
          </Toolbar>
        </AppBar>
      </div>
      {/* Edit skills */}
      {/* <div style={{ backgroundColor: "white", paddingTop: "10px" }}>
        {currentSkill && (
          <SkillInfo panel={currentSkill} updateSkill={saveSkill}></SkillInfo>
        )}
      </div>
      <Button
        variant="contained"
        onClick={() => console.log(JSON.stringify(panels))}
      >
        Print skill tree JSON
      </Button> */}
      <div
        ref={ref}
        style={{
          position: "relative",
          flexGrow: 1,
          color: "white",
        }}
      >
        {skillTree && (
          <SkillTree
            panels={panels}
            width={skillTree.width}
            height={skillTree.height}
            onClick={onPanelClick}
            explore={true}
            viewportSize={viewportSize}
          ></SkillTree>
        )}
        {selectedPanel && selectedPanel.skill && (
          <SkillInfoFinal panel={selectedPanel}></SkillInfoFinal>
        )}
      </div>
    </div>
  );
}
