import React, { useEffect, useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { ExpandMore as ExpandMoreICon } from "@material-ui/icons";

type Props = {
  acts: number[];
  selectedAct?: number;
  onActSelection: (act: number) => void;
};

export function ActMenu(props: Props) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [actOptions, setActOptions] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    setActOptions(props.acts.map((act) => `Act ${act}`));
  }, [props.acts]);

  useEffect(() => {
    if (props.selectedAct) {
      setSelectedIndex(props.acts.indexOf(props.selectedAct));
    }
  }, [props.selectedAct, props.acts]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    if (selectedIndex !== index) {
      setSelectedIndex(index);
      props.onActSelection(props.acts[index]);
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ color: "white", whiteSpace: "nowrap" }}
      >
        {actOptions[selectedIndex]}
        <ExpandMoreICon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actOptions.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
