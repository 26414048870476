import { SkillPanel } from "./skill-panel";
import { Point } from "./cube-coordinates";

export type SkillTreeDataModel = {
  name: string;
  width: number;
  height: number;
  panels: SkillPanel[];
};

export class SkillTreeModel {
  panels: SkillPanel[] = [];
  width: number;
  height: number;

  constructor(json: SkillTreeDataModel) {
    if (json.panels.length > 0) {
      this.panels = json.panels;
      this.width = json.width;
      this.height = json.height;
      return;
    }
    this.width = 0;
    this.height = 0;
    this.resize(json.width, json.height);
  }

  resize(x: number, y: number) {
    const deltaX = x - this.width;
    const deltaY = y - this.height;

    if (deltaX > 0) {
      this.addColumns(deltaX, y);
      return;
    }

    if (deltaX < 0) {
      for (let i = this.width; i > x; i--) {
        this.removeColumn(i);
      }
      return;
    }

    if (deltaY > 0) {
      this.addRows(deltaY, x);
      return;
    }

    if (deltaY < 0) {
      for (let i = this.height; i > y; i--) {
        this.removeRow(i);
        return;
      }
    }
  }

  addColumns(amount: number, rowsPerColumn: number) {
    const panels = [];
    for (let j = 0; j < rowsPerColumn; j++) {
      for (let i = 0; i < amount; i++) {
        panels.push(this.getDefaultPanel({ x: this.width + i, y: j }));
      }
    }
    this.width += amount;
    this.height = rowsPerColumn;
    this.panels.push(...panels);
  }

  addRows(amount: number, columnsPerRow: number) {
    const panels = [];
    for (let i = 0; i < amount; i++) {
      for (let j = 0; j < columnsPerRow; j++) {
        panels.push(this.getDefaultPanel({ x: j, y: i + this.height }));
      }
    }
    this.height += amount;
    this.width = columnsPerRow;
    this.panels.push(...panels);
  }

  /**remove a column from the skill tree
   * Zero based index
   */
  removeColumn(index: number) {
    if (this.width > 0) {
      this.width--;
      this.panels = this.panels.filter((panel) => {
        return panel.position.x !== index;
      });
    }
  }

  /**remove a row from the skill tree
   * Zero based index
   */
  removeRow(index: number) {
    if (this.height > 0) {
      this.height--;
      this.panels = this.panels.filter((panel) => {
        return panel.position.y !== index;
      });
    }
  }

  getDefaultPanel(position: Point): SkillPanel {
    return {
      type: "normal",
      cost: 0,
      surprise: false,
      position,
      icon: "placeholder",
      status: "notavailable",
    };
  }

  getNeighboors(position: Point) {}

  getLearntNeighboors(position: Point): number {
    return 1;
  }

  panelIsLearnt(position: Point): boolean {
    if (position.x < 0 || position.y < 0) {
      return false;
    }
    const panel = this.panels.find((p) => {
      return p.position.x === position.x && p.position.y === position.y;
    });
    if (panel) {
      return true;
    }
    return false;
  }
}
