import React from "react";
import "./App.css";
// import { SkillTreeEditor } from "./components/SkillTreeEditor";
import { Explore } from "./components/Explore";

function App() {
  return (
    <div className="App" style={{ backgroundColor: "#191303" }}>
      <Explore></Explore>
      {/* <SkillTreeEditor></SkillTreeEditor> */}
    </div>
  );
}

export default App;
