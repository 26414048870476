export type CharacterName =
  | "luminary"
  | "erik"
  | "serena"
  | "veronica"
  | "sylvando"
  | "rab"
  | "jade"
  | "eight";

const characterNames = [
  "luminary",
  "erik",
  "serena",
  "veronica",
  "sylvando",
  "rab",
  "jade",
  "eight",
];

export function isCharacterName(keyInput: string): keyInput is CharacterName {
  return characterNames.includes(keyInput);
}
