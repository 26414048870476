import React, { useState } from "react";
import { Skill } from "../models/skill";
import { Typography, makeStyles, Theme, createStyles } from "@material-ui/core";
import { SkillPanel } from "../models/skill-panel";

export type Props = {
  panel: SkillPanel;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "rgba(79, 47, 18, 0.8)",
      color: "white",
      position: "relative",
      left: "50%",
      transform: "translateX(-50%)",
      opacity: "1",
      textShadow: "3px 3px 10px #000000",
      zIndex: 1,
      width: "100%",
      maxWidth: "30em",
      borderRadius: "1em",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    left: {
      minWidth: "75%",
    },
    right: {
      minWidth: "25%",
    },
    item: {
      textAlign: "center",
      justifyContent: "space-between",
      alignSelf: "center",
    },
  })
);

export function SkillInfoFinal(props: Props) {
  const classes = useStyles();
  const allskills = require("../data/skills.json") as Skill[];
  const [skillDescription, setSkillDescription] = useState<
    string | undefined
  >();
  const [mp, setMp] = useState<number | undefined>();
  const [bottomRowVisible, setBottomRowVisible] = useState(false);

  React.useEffect(() => {
    const skillName = props.panel.skill;
    const skill = allskills.find((s) => s.name === skillName);
    if (!skill) {
      return;
    }
    setSkillDescription(skill.info);
    setMp(skill.mp);
    if (skill.mp || skill.info) {
      setBottomRowVisible(true);
    } else {
      setBottomRowVisible(false);
    }
  }, [props.panel, allskills]);

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <Typography
          align="center"
          variant="subtitle1"
          gutterBottom
          className={`${classes.item} ${classes.left}`}
        >
          {props.panel.skill}
        </Typography>
        <Typography
          align="center"
          variant="subtitle1"
          gutterBottom
          className={`${classes.item} ${classes.right}`}
        >
          {props.panel.cost}P
        </Typography>
      </div>
      {bottomRowVisible && (
        <div className={classes.row}>
          <Typography
            align="center"
            variant="subtitle1"
            gutterBottom
            className={`${classes.item} ${classes.left}`}
          >
            {skillDescription}
          </Typography>
          {mp !== undefined && (
            <Typography
              align="center"
              variant="subtitle1"
              gutterBottom
              className={`${classes.item} ${classes.right}`}
            >
              {mp} MP
            </Typography>
          )}
        </div>
      )}
    </div>
  );
}
