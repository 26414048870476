import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { CharacterName } from "../models/character";

type Props = {
  character: CharacterName;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      height: theme.spacing(5),
    },
  })
);

export function CharacterHeader(props: Props) {
  const classes = useStyles();
  const characters = {
    luminary: {
      icon: require(`../assets/characters/luminary.png`),
    },
    erik: {
      icon: require(`../assets/characters/erik.png`),
    },
    serena: {
      icon: require(`../assets/characters/serena.png`),
    },
    veronica: {
      icon: require(`../assets/characters/veronica.png`),
    },
    sylvando: {
      icon: require(`../assets/characters/sylvando.png`),
    },
    rab: {
      icon: require(`../assets/characters/rab.png`),
    },
    jade: {
      icon: require(`../assets/characters/jade.png`),
    },
    eight: {
      icon: require(`../assets/characters/eight.png`),
    },
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <img
          style={props.character === "eight" ? {filter: " brightness(0.05)"} : {}}
          src={characters[props.character].icon}
          alt={props.character}
          className={classes.avatar}
        />
        <Typography variant="h6" style={{ textTransform: "capitalize" }}>
          {props.character}
        </Typography>
      </div>
    </div>
  );
}
