import React, { useEffect, useState } from "react";
import Skill from "./Skill";
import { Point } from "../models/cube-coordinates";
import { SkillPanel } from "../models/skill-panel";

type Params = {
  panels: SkillPanel[];
  width: number;
  height: number;
  onClick: (position: Point) => void;
  explore: boolean;
  viewportSize?: { width: number; height: number };
};

function SkillTree(props: Params) {
  const [columns, setColumns] = useState(props.width);
  const [rows, setRows] = useState(props.height);
  const [hexHeight, setHexHeight] = useState(100);
  const [hexWidth, setHexWidth] = useState(100);
  const [marginX, setMarginX] = useState(0);
  const [marginY, setMarginY] = useState(0);
  const [viewportSize, setViewportSize] = useState({ width: 400, height: 300 });

  useEffect(() => {
    setColumns(props.width);
    setRows(props.height);
  }, [props.width, props.height]);

  useEffect(() => {
    setViewportSize(
      props.viewportSize ? props.viewportSize : { width: 400, height: 300 }
    );
  }, [props.viewportSize]);

  useEffect(() => {
    const hexHeight = getHexHeight(
      columns,
      rows,
      viewportSize.width,
      viewportSize.height
    );
    setHexHeight(hexHeight);
    setHexWidth(hexHeight * 1.15);
  }, [viewportSize, columns, rows]);

  useEffect(() => {
    setMarginX(
      viewportSize.width -
        hexWidth * 0.75 * Math.floor(columns / 2) * 2 * 0.96 -
        (columns % 2 === 0 ? 0 : hexWidth)
    );
    setMarginY(viewportSize.height - hexHeight * rows);
  }, [viewportSize, hexHeight, hexWidth, columns, rows]);

  return (
    <div id="skilltree" style={{ overflow: "hidden", zIndex: 1 }}>
      <div
        style={{
          display: "grid",
          position: "absolute",
          top: `${marginY / 2}px`,
          left: `${marginX / 2}px`,
          gridTemplateColumns: `repeat(${columns}, ${hexWidth}px)`,
          gridTemplateRows: `repeat(${rows},   ${hexHeight}px)`,
        }}
      >
        {props.panels.map((panel, index) => (
          <Skill
            key={index}
            position={panel.position}
            width={hexWidth}
            height={hexHeight}
            panel={{
              type: panel.type,
              cost: panel.cost,
              surprise: panel.surprise,
              position: panel.position,
              icon: panel.icon,
              skill: panel.skill,
              status: panel.status,
            }}
            onClick={props.onClick}
            explore={props.explore}
          ></Skill>
        ))}
      </div>
    </div>
  );
}

function getHexHeight(
  cols: number,
  rows: number,
  windowWidth: number,
  windowHeight: number
) {
  let cW = getHexWidthFromColumns(windowWidth, cols);
  let rH = getHexHeightFromRows(windowHeight, rows);
  return Math.min(cW / 1.15, rH);
}

// function getHorizontalSpace(hexWidth: number, elements: number): number {
//   if (elements === 1) {
//     return hexWidth;
//   }
//   return hexWidth * 0.75 + getHorizontalSpace(hexWidth, elements - 1);
// }

/**
 * Gets the width for a hexagon so that a fixed number of hegaons fit the given space
 * @param space Fixed width of the area for tiling
 * @param columns the number of hexagons to fit diagonaly
 */
function getHexWidthFromColumns(space: number, columns: number) {
  return space / (0.75 * (columns - 1) + 1);
}

function getHexHeightFromRows(space: number, rows: number) {
  return space / (rows + 0.5);
}

export default SkillTree;
